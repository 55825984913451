/* App.css */
.App {
  font-family: Arial, sans-serif;
}

header {
  background-color: #ccc;
  color: #fff;
  padding: 10px 0;
  text-align: center;
}

nav {
  background-color: #ccc;
  padding: 10px 0;
  text-align: center;
  list-style: none; /* Remove bullets from the menu */
}

nav a {
  color: #333;
  text-decoration: none;
  padding: 10px 20px;
  display: inline-block; /* Ensure each link takes full width */
  text-align: center; /* Center the text */
}

nav a.activeLink {
  background-color: #666;
}

main {
  padding: 20px;
}
